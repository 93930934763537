<template>
  <div>
    <button @click="tofaceRecognition">获取</button>
  </div>
</template>
<!-- // 引入uniapp sdk -->
<!-- // <script type="text/javascript" src="//js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js"></script> -->
<script >
import request from '@/utils/request'

export default {
  name: 'Test',
  data () {
    return {
      systemVersion: ''
    }
  },
  created () {

  },
  methods: {
    tofaceRecognition () {
      // // 向app发送消息
      // const uni = window.uni
      // uni.postMessage({
      //   data: {
      //     aaa: '21312'
      //   }
      // })
      const url = 'http://maps.google.com/maps/api/geocode/json?latlng=34.72164060858988,113.624493272207&language=zh-CN&sensor=false'
      request(url).then(res => {
        console.log(res)
      })
    }

    // http://maps.google.com/maps/api/geocode/json?latlng='+latitudeP+','+longitudeP+'&language=zh-CN&sensor=false
  }

}
</script>

<style scoped lang='less'>
</style>
